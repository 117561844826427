.rich-text-editor-shell {
    border-style: solid;
    border-radius: 10px !important;
    border-width: 1px;
    border-color: #a8a8a7;
}

.rich-text-editor-shell .toolbar{
    /* border-bottom-style: solid;
    border-color: #a8a8a7 !important; */
    height: 50px !important;
}

.rich-text-dropdown{
    z-index: 2000 !important;
    overflow: auto;
    max-height: 500px;
}

.rich-text-Modal__overlay{
    z-index: 2000 !important;
}

.rich-text-Modal__modal .rich-text-Modal__content{
    color: black !important;
}