html,
body {
  min-height: 100%;
}

#__next {
  height: 100%;
}

.editor-code span {
  color: black !important;
}

code {
  background-color: rgb(240, 242, 245) !important;
  font-family: "Inter", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue" !important;
  padding: 0.1rem 0.4rem !important;
  font-size: 90% !important;
  border-radius: 0.1335rem !important;
  color: black !important;
}

/* ._filter_icon:hover {
  border-radius: 50%;
  background-color: rgba(58, 53, 65, 0.04);
} */

._rich-text-table p {
  margin: 0 !important;
}
